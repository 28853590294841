<div class="container">
  <div class="row">
    <div *ngIf="languageSelect == 'en'" class="card my-5 mx-2">
      <h1 class="text-center hiw-title my-5">
        {{ "Informed Consent" | translate }}
      </h1>

      <div class="card-body desc" style="color: #000; text-align: justify">
        <p>
          {{
            "Telehealth involves the use of electronic communications to enable healthcare providers at different
          locations to share individual patient medical information for the purpose of improving patient care.
          Telehealth services also include remote monitoring, tele-pharmacy, prescription refills, appointment
          scheduling, health information sharing, and non-clinical services, such as education programs, administration,
          and public health. Medical Group providers may include primary care practitioners, specialists, and/or
          subspecialists. The information may be used for diagnosis, therapy, follow-up, and/or education, and may
          include any combination of the following: (1) patient medical records; (2) medical images; (3) live two-way
          audio and video; (4) interactive audio; and (5) output data from medical devices and sound and video files."
              | translate
          }}
        </p>
        <p>
          {{
            "Electronic systems used will incorporate network and software security protocols to protect the
          confidentiality of patient identification and imaging data and will include measures to safeguard the data and
          to ensure its integrity against intentional or unintentional corruption."
              | translate
          }}
        </p>
        <p>
          {{
            "Primary responsibility for your medical care should remain with your local primary care doctor, if you have
          one, as does your medical record." | translate
          }}
        </p>
        <p>{{ "Expected Benefits" | translate }}:</p>
        <ul>
          <li>
            {{
              "Improved access to medical care by enabling you to remain in your local healthcare site (i.e. home)
            while the provider consults and obtains test results at distant/other sites."
                | translate
            }}
          </li>
          <li>
            {{
              "More efficient medical evaluation and management." | translate
            }}
          </li>
          <li>{{ "Obtaining the expertise of a specialist." | translate }}</li>
        </ul>
        <p>{{ "Possible Risks" | translate }}:</p>
        <ul>
          <li>
            {{
              "Delays in medical evaluation and treatment could occur due to deficiencies or failures of the equipment
            and technologies." | translate
            }}
          </li>
          <li>
            {{
              "In rare events, the provider may determine that the transmitted information is of inadequate quality,
            thus necessitating a rescheduled telehealth consult or a face-to-face meeting with your local primary care
            doctor." | translate
            }}
          </li>
          <li>
            {{
              "In very rare events, security protocols could fail, causing a breach of privacy of personal medical
            information." | translate
            }}
          </li>
          <li>
            {{
              "In rare events, a lack of access to complete medical records may result in adverse drug interactions,
            allergic reactions, or other judgment errors." | translate
            }}
          </li>
        </ul>
        <p>
          {{
            "By checking the box associated with “Informed Consent”, you acknowledge that you understand and agree with
          the following" | translate
          }}:
        </p>
        <ol>
          <li>
            {{
              "I hereby consent to receive VERA Doctors services via telehealth technologies. I understand that
            VERA Doctors and its consulting providers offer telehealth services, but that these services do not replace
            the relationship between me and my primary care doctor. I also understand it is up to the VERA Doctors
            provider to determine whether or not my needs are appropriate for a telehealth encounter."
                | translate
            }}
          </li>
          <li>
            {{
              "I have been given an opportunity to select a consulting provider from the VERA Doctors prior to the
            consult, including a review of the consulting provider’s credentials."
                | translate
            }}
          </li>
          <li>
            {{
              "I understand that the law requires health care providers to protect the privacy and security of health
            information. I understand that VERA Doctors will take steps to make sure that my health information is not
            seen by anyone who should not see it. I understand that telehealth may involve electronic communication of
            my personal medical information to other medical practitioners who may be located in other areas, including
            out of the country." | translate
            }}
          </li>
          <li>
            {{
              "I understand there is a risk of technical failures during the telehealth encounter beyond the control
            of VERA Doctors. I agree to hold harmless VERA Doctors for delays in evaluation or for information lost due to
            such technical failures." | translate
            }}
          </li>
          <li>
            {{
              "I understand that I have the right to withhold or withdraw my consent to the use of telehealth in the
            course of my care at any time, without affecting my right to future care or treatment. I understand that I
            may suspend or terminate access to the service
            at any time for any reason or for no reason. I understand that if I am experiencing a medical emergency, I
            will be directed to dial the country emergency number immediately and that the VERA Doctors health service
            specialists are not able to connect me directly to any local emergency services."
                | translate
            }}
          </li>
          <li>
            {{
              "I understand the alternatives to telehealth consultation, such as in-person services are available to
            me, and in choosing to participate in a telehealth consultation, I understand that some parts of the
            services involving physical tests may be conducted by individuals at my location, or at a testing facility,
            at the direction of the VERA Doctors consulting healthcare provider (e.g. labs or bloodwork)."
                | translate
            }}
          </li>
          <li>
            {{
              "I understand that the audio portion of my medical visit (including audio from visits that include
            video) can be recorded for purposes that may include treatment, quality improvement, improvement of health
            status, customer and patient experience, customer and patient engagement and/or behavior modification, peer
            review, payment, efficiency, cost-effectiveness and/or other purposes relating to operations and provision
            of telehealth services and I specifically consent to the recording of my medical visit. I understand that
            behavioral health visits are not recorded." | translate
            }}
          </li>
          <li>
            {{
              "I understand that I may expect the anticipated benefits from the use of telehealth in my care, but that
            no results can be guaranteed or assured." | translate
            }}
          </li>
          <li>
            {{
              "I understand that my healthcare information may be shared with other individuals for scheduling and
            billing purposes. Persons may be present during the consultation other than the VERA Doctors provider in
            order to operate the telehealth technologies. I further understand that I will be informed of their presence
            in the consultation and thus will have the right to request the following: (1) omit specific details of my
            medical history/examination that are personally sensitive to me; (2) ask non-medical personnel to leave the
            telehealth examination; and/or (3) terminate the consultation at any time."
                | translate
            }}
          </li>
          <li>
            {{
              "I understand that I will not be prescribed any Drug Enforcement Agency controlled substances nor is
            there any guarantee that I will be given a prescription at all."
                | translate
            }}
          </li>
          <li>
            {{
              "I understand that if I participate in a consultation, I have the right to request a copy of my medical
            records which will be provided to me at a reasonable cost of preparation, shipping, and delivery."
                | translate
            }}
          </li>
          <li>
            {{
              "I understand that in the event of any problem with the website or related services, I agree that my
            sole remedy is to cease using the website or terminate access to the service. Under no circumstances will
            VERA Doctors or any VERA Doctors subsidiary or affiliate be liable in any way for the use of the telehealth
            services, including but not limited to, any errors or omissions in content or infringement by any content on
            the website of any intellectual property rights or other rights of third parties, or for any losses or
            damages of any kind arising directly or indirectly out of the use of, inability to use, or the results of
            the use of the website, and any website linked to the website, or the materials or information contained on
            any or all such websites. I agree that I will not hold VERA Doctors, its subsidiaries, or affiliates liable
            for any punitive, exemplary, consequential, incidental, indirect or special damages (including, without
            limitation, any personal injury, lost profits, business interruption, loss of programs or other data on my
            computer or otherwise) arising from or in connection with your use of the website whether under a theory of
            breach of contract, negligence, strict liability, malpractice or otherwise, even if we or they have been
            advised of the possibility of such damages." | translate
            }}
          </li>
          <li>
            {{
              "I understand that VERA Doctors makes no representation that materials on this website are appropriate or
            available for use in any other location. I understand that if I access these services from a location
            outside of the Netherlands, I do so at my own risk and initiative and that I am ultimately responsible for
            compliance with any laws or regulations associated with my use."
                | translate
            }}
          </li>
        </ol>
      </div>
    </div>
    <div *ngIf="languageSelect == 'tr'" class="card my-5 mx-2">
      <h1 class="text-center hiw-title my-1">
        {{
          "SAĞLIK VERİSİ İŞLEME KONUSUNDA AYDINLATMA
        VE ONAY METNİ" | translate
        }}
      </h1>

      <div class="card-body desc" style="color: #000; text-align: justify">
        <p>
          <a href="https://www.veradoctors.com/home-page">veradoctors.com</a>
          {{
            " olarak, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) kapsamında sizlere
          tarafımızca işlenecek olan kişisel verileriniz konusunda bir seçenek sunmak istedik."
              | translate
          }}
        </p>
        <p>
          {{
            "Bu açık rıza beyan formundan önce okumuş olduğunuz KİŞİSEL VERİLERİN KORUNMASI POLİTİKASI’nda
          görebileceğiniz üzere tarafınıza verilecek online sağlık hizmeti kapsamında kişisel sağlık verilerinizin ve
          online sağlık hizmeti esnasında kaydedilecek olan görsel ve işitsel kayıtlarınızın işlenmesi gerekmektedir. Bu
          verinin işlenmesi konusunda açık rızanıza ihtiyaç duyuyoruz."
              | translate
          }}
        </p>
        <p>
          {{
            "Önemle belirtmek isteriz ki sağlık verileriniz, özel nitelikli kişisel veri niteliğinde olup, kişisel
          verilerinizin işlenip işlenmeyeceğine ilişkin rıza verip vermemek tamamen sizin takdirinizdedir; bizler bu
          konuda sizlere hiçbir zorlamada bulunamayız. Aşağıda yapacağınız seçimi sizlere bu metnin öncesinde vermiş
          olduğumuz KİŞİSEL VERİLERİN KORUNMASI POLİTİKASI doğrultusunda yapmanız gerektiğini hatırlatır, seçiminizin
          veradoctors.com websitesinin vereceği online sağlık hizmetinin icrası bakımından gerekli olduğunu bilmenizi
          ister ve bu hususların önemle altını çizeriz. Bu izni dilediğiniz zaman geri çekebileceğiniz gibi izniniz
          kapsamında işlenen kişisel verilerinizin KİŞİSEL VERİLERİN KORUNMASI POLİTİKASI kapsamında silinmesini veya
          yok edilmesini isteme hakkınız bulunmaktadır." | translate
          }}
        </p>
        <p>
          {{
            "Konuyla ilgili her türlü soru ve taleplerinizle ilgili contact@veradoctors.com adresinden bizlere bilgi
          verebilirsiniz. Vermiş olduğunuz açık rızanızı her zaman size sunulan iletişim kanalları vasıtası ile geri
          çekebilirsiniz." | translate
          }}
        </p>
      </div>
    </div>
  </div>
</div>
