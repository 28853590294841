import { HospitalDetailService } from './../../services/hospital-detail.service';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  EventEmitter,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { Event, NavigationStart, Router, NavigationEnd } from '@angular/router';

import { CommonServiceService } from './../../common-service.service';

import { headerService } from './services/services.header';
import { SocialAuthService } from 'angularx-social-login';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { localStorageService } from 'src/app/services/local-storage.service';
import { MessagingService } from 'src/app/agora/services/messaging.service';
import { MessagingService as FireMessagingService } from 'src/app/services/messaging.service';
import { DoctorsService } from 'src/app/services/doctors.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UsersService } from 'src/app/services/users.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  //providers: [headerService],
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  usernamesurname: any;
  userrole: any;
  userimage: any = localStorage.getItem('image');
  orders = [];
  public language: string;
  auth: boolean = false;
  isPatient: boolean = false;
  isAdmin: boolean = false;
  isHospitalAdmin: boolean = false;
  isDoctor: boolean = false;
  isDoctorCandidate: boolean = false;
  isThyAdmin: boolean = false;
  //isCallCenter: boolean = false;
  page;
  splitVal;
  headerTop: boolean = true;
  base;
  url1;
  allLangs;
  hospitalSlugs: any = [];

  // *****notification*****
  lastMSG;
  messageBox = [];
  messageBoxShow = [];

  // ******languages*****
  languageAreas: boolean;
  localLang: string = localStorage.getItem('language');

  //********* start call languages ********
  preferencedLanguages = [
    { lang: 'Start Call', code: 'en' },
    { lang: 'Aramayı Başlat', code: 'tr' },
    { lang: 'Начать звонок', code: 'ru' },
    { lang: 'Iniciar llamada', code: 'es' },
    { lang: 'Anruf starten', code: 'de' },
    { lang: "Démarrer l'appel", code: 'fr' },
    { lang: 'Oproep starten', code: 'nl' },
  ];

  isCallCenter;
  constructor(
    private authService: SocialAuthService,
    private languageSer: LanguageService,
    private _headerService: headerService,
    private cdr: ChangeDetectorRef,
    public router: Router,
    public commonService: CommonServiceService,
    public translate: TranslateService,
    private localStorageService: localStorageService,
    private authServiceApp: AuthService,
    private messagingService: MessagingService,
    private fireMessagingService: FireMessagingService,
    private doctorSer: DoctorsService,
    private toastr: ToastrService,
    private userService: UsersService,
    private hospitalDetailService: HospitalDetailService
  ) {
    if (localStorage.getItem('xauth')) {
      this.localStorageService.onSubjectName.subscribe((data) => {
        this.usernamesurname = data;
      });
      this.localStorageService.onSubjectRole.subscribe((data) => {
        this.userrole = data;
      });
      this.localStorageService.onSubjectImage.subscribe((data) => {
        this.userimage = data;
      });
    }

    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        var res = event.url.split('/');
        this.base = res[1];
        this.page = res[2];
        /* if (event.url == '/home-slider-one') {
          this.headerTop = true;
        } else {
          this.headerTop = false;
        }*/
      }
    });
    this.url1 = this.router.url;
    this.commonService.message.subscribe((res) => {
      if (res === 'user') {
        this.auth = true;
        this.isPatient = true;
      } else if (res === 'doctor') {
        this.auth = true;
        this.isDoctor = true;
      } else if (res === 'doctor-candidate') {
        this.auth = true;
        this.isDoctorCandidate = true;
      } else if (res === 'admin') {
        this.auth = true;
        this.isAdmin = true;
      } else if (res === 'hospital-admin') {
        this.auth = true;
        this.isHospitalAdmin = true;
      } else if (res === 'thy-admin') {
        this.auth = true;
        this.isThyAdmin = true;
      } else if (res === 'call-center') {
        this.auth = true;
        this.isCallCenter = true;
      } else if (res === 'logout') {
        this.auth = false;
        this.isPatient = false;
      }
    });

    this.languageSer.getLanguages().subscribe((res) => {
      this.allLangs = res as Array<any>;
      this.localLanguage =
        this.localLang || window.navigator.language.split('-')[0];

      const detectLanguage = (res as Array<any>).find(
        (i) => i.code === this.localLanguage
      );

      translate.addLangs(this.allLangs);
      detectLanguage
        ? translate.setDefaultLang(this.localLanguage)
        : translate.setDefaultLang('en');
      this.languageSer.putSelectedLanguage(detectLanguage?.code);
    });

    // window.matchMedia('(min-width: 576px)')
    //   ? (this.languageAreas = true)
    //   : false;
    // this.subscription = this.doctorSer
    //   .makeMeAvailable('offline')
    //   .subscribe((value) => {
    //     console.log(value);
    //   });
  }

  localLanguage = this.localLang || window.navigator.language.split('-')[0]; // exp: tr-TR
  seletOptionLanguage: string;
  onChangeLanguage(event: string): void {
    this.translate.use(event);
    this.languageSer.putSelectedLanguage(event);
    this.seletOptionLanguage = event;
    this.openFlag = false;
    // window.location.reload();
  }
  openFlag: boolean = false;

  /*@HostListener('window:beforeunload', ['$event'])
   beforeunloadHandler(event) {

    if(this.isDoctor==true){
      this.doctorSer.makeMeAvailable('offline').subscribe();
    }

    return false;

  }*/

  /* @HostListener('window:beforeunload')
  async ngOnDestroy() {

    console.log((window.performance.getEntries()[0] as PerformanceNavigationTiming).type ,"-----------------")
    if((window.performance.getEntries()[0] as PerformanceNavigationTiming).type  === 'reload'){
      debugger;
    }
    else{
      this.subscription = this.doctorSer.makeMeAvailable('offline').subscribe(value => {
        console.log(value);
      });
      debugger;

      this.subscription.unsubscribe();
    }



  }*/

  ngOnDestroy() {
    if (this.isDoctor == true) {
      this.subscription.unsubscribe();
    }
  }

  getUserMeControl() {
    if (localStorage.getItem('xauth')) {
      this.userService.getUserMe().subscribe(
        (res) => {
          // const userMe: any = res.body;
          // const isRoleDoctor = userMe.user.role === 'doctor';
          // const notificationToken = userMe.user.notificationToken;

          // console.log('object: ', 'userMe');

          // if (isRoleDoctor && !notificationToken) {
          //   this.fireMessagingService.askForPermission();
          // }

          // if (isRoleDoctor && notificationToken) {
          //   this.fireMessagingService.askForNewPermission(notificationToken);
          // }

          console.log('control me: successfully');
        },
        (error) => {
          console.error('control me: failed', error);

          if (error.status === 401) {
            this.logout();
          }
        }
      );
    }
  }

  getHospitalSlugs() {
    this.hospitalDetailService.getHospitalsSlug().subscribe(
      (res) => {
        this.hospitalSlugs = (
          res.body as {
            slug: string;
            _id: string;
            hospital: { name: string };
          }[]
        ).map((hospitalSlug) => {
          return {
            slug: hospitalSlug?.slug,
            name: hospitalSlug?.hospital?.name,
            // name: this.formatAndCapitalize(hospitalSlug.slug),
          };
        });
      },
      (error) => {
        console.error('control hospital slugs: failed', error);
      }
    );
  }

  // formatAndCapitalize(input: string): string {
  //   return input
  //     .split('-')
  //     .map(
  //       (word) =>
  //         word.charAt(0).toLocaleUpperCase() + word.slice(1).toLocaleLowerCase()
  //     )
  //     .join(' ');
  // }

  ngOnInit(): void {
    this.getHospitalSlugs();
    this.seletOptionLanguage =
      this.localLang || window.navigator.language.split('-')[0];

    this.getUserMeControl();

    this.checkWindowSize();

    this.authServiceApp.userLoggedIn$.subscribe((user: any) => {
      if (user) {
        this.usernamesurname = localStorage.getItem('usernamesurname');
        this.userimage = localStorage.getItem('image');
        this.userrole = localStorage.getItem('role');
      }
    });

    // this.messagingService.getMessagesFromDB().subscribe(res => {
    //   this.messageBox = (res.body as unknown as Array<any>).slice(0,5)
    //   this.messageBoxShow = this.messageBox
    // })

    // this.usernamesurname = localStorage.getItem('usernamesurname');
    // this.userimage = localStorage.getItem('image');
    // this.userrole = localStorage.getItem('role');

    this._headerService.selectedlanguage.subscribe(
      (lang) => (this.language = lang)
    );

    this._headerService.GetLanguages().subscribe((data) => {
      this.orders = data;
    });

    if (localStorage.getItem('auth') === 'true') {
      this.auth = true;
      this.isPatient = localStorage.getItem('user') === 'true' ? true : false;
      this.isDoctor = localStorage.getItem('doctor') === 'true' ? true : false;
      this.isDoctorCandidate =
        localStorage.getItem('doctor-candidate') === 'true' ? true : false;
      this.isAdmin = localStorage.getItem('admin') === 'true' ? true : false;
      this.isHospitalAdmin =
        localStorage.getItem('hospital-admin') === 'true' ? true : false;
      this.isCallCenter =
        localStorage.getItem('call-center') === 'true' ? true : false;
      this.isThyAdmin =
        localStorage.getItem('thy-admin') === 'true' ? true : false;
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        $('html').removeClass('menu-opened');
        $('.sidebar-overlay').removeClass('opened');
        $('.main-wrapper').removeClass('slide-nav');
      }
    });
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.loadDynmicallyScript('assets/js/script.js');
  }
  loadDynmicallyScript(js) {
    var script = document.createElement('script');
    script.src = js;
    script.async = false;
    document.head.appendChild(script);
    script.onload = () => this.doSomethingWhenScriptIsLoaded();
  }
  doSomethingWhenScriptIsLoaded() {}
  change(name) {
    this.page = name;
    this.commonService.nextmessage('main');
  }

  mapGrid() {
    this.router.navigate(['/map-grid']);
  }

  mapList() {
    this.router.navigate(['/map-list']);
  }

  addStyle(val) {
    if (val === 'home') {
      if (document.getElementById('home').style.display == 'block') {
        document.getElementById('home').style.display = 'none';
      } else {
        document.getElementById('home').style.display = 'block';
      }
    }
    if (val === 'doctor') {
      if (document.getElementById('doctor').style.display == 'block') {
        document.getElementById('doctor').style.display = 'none';
      } else {
        document.getElementById('doctor').style.display = 'block';
      }
    }
    if (val === 'patient') {
      if (document.getElementById('patient').style.display == 'block') {
        document.getElementById('patient').style.display = 'none';
      } else {
        document.getElementById('patient').style.display = 'block';
      }
    }
    if (val === 'pharmacy') {
      if (document.getElementById('pharmacy').style.display == 'block') {
        document.getElementById('pharmacy').style.display = 'none';
      } else {
        document.getElementById('pharmacy').style.display = 'block';
      }
    }
    if (val === 'pages') {
      if (document.getElementById('pages').style.display == 'block') {
        document.getElementById('pages').style.display = 'none';
      } else {
        document.getElementById('pages').style.display = 'block';
      }
    }
    if (val === 'blog') {
      if (document.getElementById('blog').style.display == 'block') {
        document.getElementById('blog').style.display = 'none';
      } else {
        document.getElementById('blog').style.display = 'block';
      }
    }
    if (val === 'admin') {
      if (document.getElementById('admin').style.display == 'block') {
        document.getElementById('admin').style.display = 'none';
      } else {
        document.getElementById('admin').style.display = 'block';
      }
    }
    if (val === 'hospital-admin') {
      if (document.getElementById('hospital-admin').style.display == 'block') {
        document.getElementById('hospital-admin').style.display = 'none';
      } else {
        document.getElementById('hospital-admin').style.display = 'block';
      }
    }
    if (val === 'thy-admin') {
      if (document.getElementById('thy-admin').style.display == 'block') {
        document.getElementById('thy-admin').style.display = 'none';
      } else {
        document.getElementById('thy-admin').style.display = 'block';
      }
    }
    // if (val === 'callCenter') {
    //   if (document.getElementById('callCenter').style.display == 'block') {
    //     document.getElementById('callCenter').style.display = 'none';
    //   } else {
    //     document.getElementById('callCenter').style.display = 'block';
    //   }
    // }
  }

  doctor(name) {
    this.page = name;
    this.router.navigate(['/doctor/dashboard']);
  }

  logout() {
    if (this.isDoctor == true) {
      console.log('geldim, logout oldum');
      this.makeMeUnavailable();
    }

    this.signOut();

    this.localStorageService.clearLocalStorage();
    this.authServiceApp.updateAuthStatus();

    this.usernamesurname = '';
    this.userrole = '';

    this.auth = false;
    this.isPatient = false;
    this.isDoctor = false;
    this.isAdmin = false;
    this.isThyAdmin = false;
    //this.isCallCenter = false;

    this.commonService.nextmessage('logout');
    this.router.navigate(['/']);
  }

  makeMeUnavailable() {
    this.doctorSer.makeMeAvailable('offline').subscribe(
      (res) => {
        //this.toastr.success("I am unavailable")
      },
      (err) => {
        //this.toastr.error('something went wrong', 'please try again later',err)
      }
    );
  }

  signOut(): void {
    this.authService.signOut();
  }
  home() {
    this.commonService.nextmessage('main');
    this.router.navigateByUrl('/').then(() => {
      this.router.navigate(['/']);
    });
  }

  navigate(name) {
    this.page = name;
    if (name === 'Admin') {
      this.router.navigate(['/admin']);
      this.commonService.nextmessage('admin');
    } else if (name === 'Pharmacy Admin') {
      this.router.navigate(['/pharmacy-admin']);
      this.commonService.nextmessage('pharmacy-admin');
    } else if (name === 'Hospital Admin') {
      this.router.navigate(['/hospital-admin']);
      this.commonService.nextmessage('hospital-admin');
    } else if (name === 'Thy Admin') {
      this.router.navigate(['/thy-admin']);
      this.commonService.nextmessage('thy-admin');
    }
  }

  isActive(route: string): boolean {
    return this.router.url.includes(route);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkWindowSize();
  }

  checkWindowSize() {
    if (window.innerWidth > 992) {
      const htmlElement = document.querySelector('html');
      const overlayElement = document.querySelector('.sidebar-overlay.opened');

      if (htmlElement && htmlElement.classList.contains('menu-opened')) {
        htmlElement.classList.remove('menu-opened');

        if (overlayElement) {
          overlayElement.classList.remove('opened');
        }
      }
    }
  }
}
