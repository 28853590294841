<div class="w-100 custom-credit-card">
  <form [formGroup]="form" novalidate>
    <!-- Cardholder Name -->
    <div class="form-row">
      <div class="form-group col-12 fix-margin">
        <label
          >{{ "Cardholder Name" | translate
          }}<span class="required">*</span></label
        >
        <mat-form-field appearance="outline" class="full-width">
          <input
            matInput
            placeholder="{{ 'Cardholder Name' | translate }}"
            formControlName="cardholderName"
            type="text"
            autocomplete="cc-name"
            (change)="sendFormData()"
          />
        </mat-form-field>
      </div>
    </div>

    <!-- Credit Card Number -->
    <div class="form-row">
      <div class="form-group col-12 fix-margin">
        <label
          >{{ "Card Number" | translate }}<span class="required">*</span></label
        >
        <mat-form-field appearance="outline" class="full-width">
          <input
            matInput
            placeholder="{{ 'Card Number' | translate }}"
            formControlName="creditCard"
            type="tel"
            autocomplete="cc-number"
            (input)="formatCardNumber($event); sendFormData()"
          />
          <span
            matSuffix
            *ngIf="cardType; else defaultIcon"
            style="display: flex; align-items: center"
          >
            <img
              [src]="
                '/assets/img/card-logos/logo-' + cardType.toLowerCase() + '.png'
              "
              width="40"
              alt="{{ cardType }} Logo"
              style="max-height: 24px"
            />
          </span>
          <ng-template #defaultIcon>
            <mat-icon matSuffix>credit_card</mat-icon>
          </ng-template>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <!-- Expiration Date (MM/YY) -->
      <div class="form-group col-6">
        <label
          >{{ "Expiration Date" | translate
          }}<span class="required">*</span></label
        >
        <mat-form-field appearance="outline" class="full-width">
          <input
            matInput
            placeholder="MM/YY"
            formControlName="expirationDate"
            type="tel"
            autocomplete="cc-exp"
            maxlength="5"
            (input)="formatExpDate($event)"
          />
        </mat-form-field>
      </div>
      <!-- CVC -->
      <div class="form-group col-6">
        <label>{{ "CVC" | translate }}<span class="required">*</span></label>
        <mat-form-field appearance="outline" class="full-width">
          <input
            matInput
            placeholder="CVC"
            formControlName="cvc"
            type="tel"
            autocomplete="off"
            (input)="restrictCVCInput($event)"
          />
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
