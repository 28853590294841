import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SearchPipe } from './search.pipe';
import { ShortenPipe } from './shorten.pipe';
import { SpinAllComponent } from './spin-all.component';
import { TermsofuseComponent } from './terms/termsofuse.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { InformedconsentComponent } from './informed-consent/informedconsent.component';
import { DisclaimershareComponent } from './disclaimer/disclaimershare.component';
import { KvkkComponent } from './kvkk/kvkk.component';
import { PatientClarificationComponent } from './patient-clarification/patient-clarification.component';
import { CreditCardComponent } from '../credit-card/credit-card.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    SearchPipe,
    SpinAllComponent,
    ShortenPipe,
    TermsofuseComponent,
    PrivacyComponent,
    InformedconsentComponent,
    DisclaimershareComponent,
    KvkkComponent,
    KvkkComponent,
    CreditCardComponent,
    PatientClarificationComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  exports: [
    SearchPipe,
    SpinAllComponent,
    ShortenPipe,
    TermsofuseComponent,
    PrivacyComponent,
    InformedconsentComponent,
    DisclaimershareComponent,
    KvkkComponent,
    CreditCardComponent,
    PatientClarificationComponent,
  ],
})
export class SharedModule {}
