<div class="container">
  <div class="row">
    <div class="card my-5 mx-2 w-100">
      <div class="card-header text-center pt-5">
        <h1>{{ "COVID-19" | translate }}</h1>
      </div>
      <div
        class="card-body px-5 col-md-12 col-sm-12 w-100 text-center"
        style="color: #000; text-align: justify"
      >
        <p class="desc">
          {{
            "In January 2020, the World Health Organization declared the outbreak of a new Novel Coronavirus. VERA Doctors is committed to helping you stay informed and protected. For further information on Covid-19,"
              | translate
          }}
          <a
            href="https://covid19.who.int/"
            target="_blank"
            style="color: #4365ef"
            >{{ "visit WHO" | translate }}</a
          >.
        </p>
        <h5 class="desc text-left mt-5">{{ "WHAT IS COVID" | translate }}</h5>
        <p class="desc text-left mt-3">
          {{
            "The Coronavirus is a large family of viruses common in people and some animals. The current outbreak leads to a condition called COVID-19. Those who contract COVID-19 usually experience one or more of these symptoms:"
              | translate
          }}
        </p>
        <div class="card desc text-left mt-3">
          <ul class="mt-3 font-weight-bold">
            <li>{{ "Cough" | translate }}</li>
            <li>{{ "Shortness of Breath" | translate }}</li>
            <li>{{ "Fever" | translate }}</li>
            <li>{{ "Chills" | translate }}</li>
            <li>{{ "Repeated Shaking with Chills" | translate }}</li>
            <li>{{ "Headache" | translate }}</li>
            <li>{{ "Soar Throat" | translate }}</li>
            <li>{{ "Muscle Pain" | translate }}</li>
            <li>{{ "New loss of taste or smell" | translate }}</li>
          </ul>
        </div>
        <h5 class="desc text-left mt-5">
          {{ "HOW DOES IT SPREAD" | translate }}
        </h5>
        <p class="desc text-left mt-3">
          {{
            "COVID-19 mainly spreads human-to-human by contact with respiratory
          droplets of an infected person by way of ones mouth or nose. Spread is most likely when peple are in close proximity to one another (6 feet) while coughing, sneezing, talking,
          or working out." | translate
          }}
        </p>
        <p class="desc text-left mt-3">
          {{
            "If you think you may have been exposed, please contact your healthcare provider immediately."
              | translate
          }}
        </p>
        <h5 class="desc text-left mt-5">
          {{ "PREVENT EXPOSURE" | translate }}
        </h5>
        <p class="desc text-left mt-3">
          {{
            "These are recommended measures that help prevent the spread of infection such as COVID-19, cold, flu, and more."
              | translate
          }}
        </p>
        <div class="card desc text-left mt-3">
          <ul class="mt-3 font-weight-bold">
            <li>
              {{ "Regularly clean surfaces with disinfectant" | translate }}
            </li>
            <li>
              {{
                "Wash your hand frequently with warm water and soap for 20 seconds"
                  | translate
              }}
            </li>
            <li>
              {{
                "Avoid touching your nose and mouth with dirty hands"
                  | translate
              }}
            </li>
            <li>
              {{ "Avoid close contact with those who are sick" | translate }}
            </li>
            <li>
              {{
                "Avoid large crowds and gatherings, especially indoors"
                  | translate
              }}
            </li>
            <li>
              {{
                "Wear a face mask in public settings where you cannot socially distance"
                  | translate
              }}
            </li>
          </ul>
        </div>
        <h5 class="desc text-left mt-5">{{ "VERA Doctors" | translate }}</h5>
        <p class="desc text-left mt-3">
          {{ "Our providers can:" | translate }}
        </p>
        <div class="card desc text-left mt-3">
          <ul class="mt-3 font-weight-bold">
            <li>
              {{
                "Screen for COVID-19 and determine best course of treatment"
                  | translate
              }}
            </li>
            <li>
              {{ "Provide Antibody tests" | translate }}
              <span class="font-weight-normal">{{
                "(these tests do not tell you if you have an active infection)"
                  | translate
              }}</span>
            </li>
            <li>
              {{
                "Write medical notes excusing you from school or work because of COVID-19"
                  | translate
              }}
              <span class="font-weight-normal">{{
                "(for up to 2 weeks)" | translate
              }}</span>
            </li>
          </ul>
        </div>
        <p class="desc text-left mt-3 my-5">
          {{
            "Many people have never experienced a Pandemic of this level before. It’s okay to feel frightened or worried. If you have any questions regarding Covid-19 or just need someone to talk to, sign up for a talk therapy or teen therapy session with VERA Doctors now."
              | translate
          }}
        </p>
      </div>
    </div>
  </div>
</div>
