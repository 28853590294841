<div class="container">
  <div class="row">
    <div class="card my-5 w-100 mx-2">
      <div
        class="card-body col-md-12 col-sm-12 w-100"
        style="color: #000; text-align: justify"
      >
        <h1 class="text-center hiw-title">
          {{ "Frequently Asked Questions" | translate }}
        </h1>
        <div class="accordion mt-5" id="accordionExample">
          <h4 class="text-center hiw-title">
            {{ "For Service Users" | translate }}
          </h4>
          <div class="card new">
            <div class="card-header" id="1">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left"
                  type="button"
                  data-toggle="collapse"
                  data-target="#c1"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  {{ "How much does VERA Doctors cost?" | translate }}
                </button>
              </h2>
            </div>
            <div
              id="c1"
              class="collapse show"
              aria-labelledby="1"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{
                      "Patients can pay for healthcare in two different ways:"
                        | translate
                    }}
                  </p>
                  <p class="font-weight-bold">
                    {{ "Pay per Service" | translate }}
                  </p>
                  <p>
                    {{
                      "Patients pay for each service requested and approved."
                        | translate
                    }}
                  </p>
                  <p class="font-weight-bold">
                    {{ "Credit or Membership Packages:" | translate }}
                  </p>
                  <p>
                    {{
                      "Patients can pay in advance by purchasing credits in advance or by choosing a subscription package. Each service they receive is deducted from their credit balance."
                        | translate
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="card new">
            <div class="card-header" id="2">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#c2"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  {{
                    "Will my results be sent to my primary physician?"
                      | translate
                  }}
                </button>
              </h2>
            </div>
            <div
              id="c2"
              class="collapse"
              aria-labelledby="2"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{
                      "Digital health records are data that individuals own the rights to their own production and use. These records can only be viewed by doctors authorized by the individual or who produced the record. Other than that, any doctor or official in a healthcare facility who is not the patient's official doctor cannot access this information."
                        | translate
                    }}
                  </p>
                  <p>
                    {{
                      "When patients consult a doctor, if they consent to the doctor seeing their previous health information, doctors can access this information. However, this is only possible with the explicit consent of the patient. This protects patients' privacy and control over their health records."
                        | translate
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card new">
            <div class="card-header" id="3">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#c3"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  {{
                    "Do I need to set up an account to use VERA Doctors?"
                      | translate
                  }}
                </button>
              </h2>
            </div>
            <div
              id="c3"
              class="collapse"
              aria-labelledby="3"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{
                      "Patients and clients must also create an account to use VERA Doctors. This account is necessary to ensure the security of personal health data."
                        | translate
                    }}
                  </p>
                  <p>
                    {{
                      "Only basic identification information is requested during account creation and this information is stored in the system to protect the confidentiality of patient data."
                        | translate
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="card new">
            <div class="card-header" id="4">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#c4"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  {{
                    "Is all of my information private and secure?" | translate
                  }}
                </button>
              </h2>
            </div>
            <div
              id="c4"
              class="collapse"
              aria-labelledby="4"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{
                      "VERA Premium Care Group is committed to protecting the privacy of every individual who uses our services. We comply with all state, national and international laws and regulations regarding GDPR, HIPAA and GDPR."
                        | translate
                    }}
                  </p>
                  <p>
                    {{
                      "The Health Insurance Portability and Accountability Act of 1996 (HIPAA) is a federal law that
                    required the creation of national standards to protect sensitive patient health information from being
                    disclosed without the patient's consent or knowledge."
                        | translate
                    }}
                  </p>
                  <p>
                    {{
                      "The General Data Protection Regulation (GDPR) is a legal framework that sets guidelines for the
                    collection and processing of personal information from individuals who live in and outside of the
                    European Union (EU). Approved in 2016." | translate
                    }}
                  </p>
                  <p>
                    {{
                      "Unless permitted or required by law, your medical information will not be shared without your
                  consent. On occasion, we may use de-identified information to help us improve our clinical programs."
                        | translate
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card new">
            <div class="card-header" id="5">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#c5"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  {{
                    "What kind of services can I get from VERA Doctors in which branches?"
                      | translate
                  }}
                </button>
              </h2>
            </div>
            <div
              id="c5"
              class="collapse"
              aria-labelledby="5"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{
                      "VVERADoctors, as an online healthcare platform, does not directly provide services itself; instead, it delivers the services of contracted healthcare facilities to you."
                        | translate
                    }}
                  </p>
                  <p>
                    {{
                      "Our contracted health facilities offer treatment and health services in almost all branches and diseases. By accessing these facilities, you can easily get the health services that suit your needs."
                        | translate
                    }}
                  </p>
                  <p>
                    {{
                      "You can click on the link below to access the full list of our contracted healthcare facilities:"
                        | translate
                    }}
                    <a
                      href="javascript:void(0);"
                      [routerLink]="'/what-we-treat'"
                      target="_blank"
                    >
                      {{ "platform." | translate }}</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card new">
            <div class="card-header" id="6">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#c6"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  {{ "Does VERA Doctors accept my insurance?" | translate }}
                </button>
              </h2>
            </div>

            <div
              id="c6"
              class="collapse"
              aria-labelledby="6"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="card-body-new">
                  <p class="mb-3">
                    {{
                      "Healthcare facilities working with the VERA Doctors system offer two important advantages in insurance terms:"
                        | translate
                    }}
                  </p>
                  <p class="font-weight-bold">
                    {{ "Insurance Contracted Health Facilities:" | translate }}
                  </p>
                  <p>
                    {{
                      "If the health facility providing the service has an agreement with SSI or other insurance companies, you can benefit from these services through the VERA Doctors platform."
                        | translate
                    }}
                  </p>
                  <p class="font-weight-bold">
                    {{ "Special Insurance Company Agreements:" | translate }}
                  </p>
                  <p>
                    {{
                      "Thanks to its agreements with private insurance companies, VERA Doctors offers special discounts and advantages in the services provided by health facilities included in this scope."
                        | translate
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card new">
            <div class="card-header" id="7">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#c7"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  {{ "Where can I use VERA Doctors?" | translate }}
                </button>
              </h2>
            </div>
            <div
              id="c7"
              class="collapse"
              aria-labelledby="7"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{
                      "VERA Doctors is a health informatics system that provides remote health services and works locally and in the cloud. You can easily access this system from devices such as computers, cell phones or tablets."
                        | translate
                    }}
                  </p>
                  <p>
                    {{
                      "VERA Doctors offers you great flexibility in accessing healthcare by eliminating the boundaries of time and space. You can receive services from hospitals and doctors in your province, or you can reach health specialists in another city or outside the country. Thus, it is possible to get the healthcare you need from anywhere, anytime."
                        | translate
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card new">
            <div class="card-header" id="8">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#c8"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  {{ "How can I create an account?" | translate }}
                </button>
              </h2>
            </div>
            <div
              id="c8"
              class="collapse"
              aria-labelledby="8"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{
                      "As a patient or client, you can become a member quickly by logging into VERA Doctors website or mobile application and clicking on the “Register” button."
                        | translate
                    }}
                  </p>
                  <p>
                    {{
                      "After completing the membership process, the system will send you your membership information and login password. You can start benefiting from health services by logging into your account with this information."
                        | translate
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card new">
            <div class="card-header" id="9">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#c9"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  {{ "Is there a time limit for online meetings?" | translate }}
                </button>
              </h2>
            </div>
            <div
              id="c9"
              class="collapse"
              aria-labelledby="9"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{
                      "The duration of each call is 15 minutes. The doctor can add 5 minutes if needed."
                        | translate
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card new">
            <div class="card-header" id="10">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#c10"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  {{ "How do I start my video call?" | translate }}
                </button>
              </h2>
            </div>
            <div
              id="c10"
              class="collapse"
              aria-labelledby="10"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{
                      "Once you have booked a video visit, we will send you both a confirmation and a reminder about your appointment."
                        | translate
                    }}
                  </p>
                  <p>
                    {{
                      "You can join your video call by clicking the link in the message sent to your e-mail or sms. Or you can go to your profile on your computer or our application and click on the appointments tab. Please make sure that your internet connection is strong and the programs you use are up to date."
                        | translate
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card new">
            <div class="card-header" id="11">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#c11"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  {{
                    "How do I change or update billing information?" | translate
                  }}
                </button>
              </h2>
            </div>
            <div
              id="c11"
              class="collapse"
              aria-labelledby="11"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{
                      "Billing information and address information for patients and clients can be easily updated from the “Dashboard” and “Billing” section after logging into your VERA Doctors profile."
                        | translate
                    }}
                  </p>
                  <p>
                    {{
                      "To edit your billing information, please click on the link below to access the relevant page:"
                        | translate
                    }}
                    <a
                      href="javascript:void(0);"
                      [routerLink]="'/patients/settings'"
                      target="_blank"
                    >
                      {{ "Profile Settings" | translate }}</a
                    >
                  </p>
                  <p>
                    {{
                      "With these simple steps you can quickly change your information."
                        | translate
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card new">
            <div class="card-header" id="12">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#c12"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  {{ "How can I make an appointment online?" | translate }}
                </button>
              </h2>
            </div>
            <div
              id="c12"
              class="collapse"
              aria-labelledby="12"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="card-body-new">
                  <p>
                    {{
                      "After logging in to the website or mobile app, you can search by disease, specialty or health test. By reviewing the profiles of the doctors that match your request, you can easily book from available time slots on open appointment dates."
                        | translate
                    }}
                  </p>
                  <p>
                    {{
                      "In this way, you can quickly and practically make an online appointment with the healthcare professional that suits your needs."
                        | translate
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card new">
          <div class="card-header" id="13">
            <h2 class="mb-0">
              <button
                class="btn btn-link btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#c13"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                {{ "What will happen if I cancel my appointment?" | translate }}
              </button>
            </h2>
          </div>
          <div
            id="c13"
            class="collapse"
            aria-labelledby="13"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="card-body-new">
                <p>
                  {{
                    "If your appointment is less than 24 hours away, no refund will be given in case of cancellation."
                      | translate
                  }}
                </p>
                <p>
                  {{
                    "If you cancel appointments with more than 24 hours left, your balance will be credited to your account and you can use it again at any time."
                      | translate
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <h4 class="text-center hiw-title">
          {{ "For Service Providers" | translate }}
        </h4>

        <div class="card new">
          <div class="card-header" id="14">
            <h2 class="mb-0">
              <button
                class="btn btn-link btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#c14"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                {{ "Who are the Service Providers?" | translate }}
              </button>
            </h2>
          </div>
          <div
            id="c14"
            class="collapse"
            aria-labelledby="14"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="card-body-new">
                <p>
                  {{
                    "This group includes legal entities such as healthcare facilities, hospitals, medical centers and medical practices that are authorized to provide healthcare services. These legal entities can register their employed doctors or owners in the system. If a doctor is only a practice owner, he/she can register both the practice and himself/herself as an employee."
                      | translate
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="card new">
          <div class="card-header" id="15">
            <h2 class="mb-0">
              <button
                class="btn btn-link btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#c15"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                {{ "How much does VERA Doctors cost?" | translate }}
              </button>
            </h2>
          </div>
          <div
            id="c15"
            class="collapse"
            aria-labelledby="15"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="card-body-new">
                <p>
                  {{
                    "Healthcare facilities or doctors are not initially charged a fee when registering in the system."
                      | translate
                  }}
                </p>
                <p>
                  {{
                    "Large organizations or doctors can offer their services through VERA Doctors at no additional cost by paying a monthly subscription fee."
                      | translate
                  }}
                </p>
                <p>
                  {{
                    "In cases where the subscription fee is not paid, the costs of VERA Doctors infrastructure and usage services received from the services are covered by deducting from the fee charged to the patient."
                      | translate
                  }}
                </p>
                <p>
                  {{
                    "This system offers flexible payment methods to suit the needs of users, while enabling healthcare facilities and doctors to provide services efficiently."
                      | translate
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card new">
          <div class="card-header" id="16">
            <h2 class="mb-0">
              <button
                class="btn btn-link btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#c16"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                {{ "How can I create an account?" | translate }}
              </button>
            </h2>
          </div>
          <div
            id="c16"
            class="collapse"
            aria-labelledby="16"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="card-body-new">
                <p>
                  {{
                    "As a healthcare facility or healthcare professional, you can start your pre-registration by visiting the VERA Doctors website or mobile app and clicking the 'Register' button."
                      | translate
                  }}
                </p>
                <p class="font-weight-bold">
                  {{
                    "After we receive your pre-membership application:"
                      | translate
                  }}
                </p>
                <p>
                  {{
                    "Our communication department will contact you." | translate
                  }}
                </p>
                <p>
                  {{
                    "Data such as your official information, diploma numbers, health facility license and registration information will be verified."
                      | translate
                  }}
                </p>
                <p>
                  {{
                    "Once your details have been verified and you have completed the pre-training process, your account will be activated."
                      | translate
                  }}
                </p>
                <p>
                  {{
                    "Once your account is approved, you can access the services on the platform.."
                      | translate
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card new">
          <div class="card-header" id="17">
            <h2 class="mb-0">
              <button
                class="btn btn-link btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#c17"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                {{
                  "How can I create an appointment slot in VERA Doctors?"
                    | translate
                }}
              </button>
            </h2>
          </div>
          <div
            id="c17"
            class="collapse"
            aria-labelledby="17"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="card-body-new">
                <p>
                  {{
                    "To create an appointment slot in VERA Doctors, log in to the Dashboard section and click on the “Schedule Timings” tab. From this area, you can easily create, edit or update your existing appointment slots."
                      | translate
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
