import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { DataService } from './data.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import {
  NgbModule,
  NgbPaginationModule,
  NgbRating,
  NgbRatingModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Daterangepicker } from 'ng2-daterangepicker';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angularx-social-login';

import { RoleGuardService } from './auth/role-guard.service';
import { AuthService } from './auth/auth.service';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { NgxStripeModule } from 'ngx-stripe';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from '../lib/token.interceptor';
import { LanguageService } from './services/language.service';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { configFirebase } from 'src/config/firebase';

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { QuillModule } from 'ngx-quill';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AsyncPipe } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { PrivacyPolicyComponent } from './information-pages/privacy-policy/privacy-policy.component';
import { DisclaimerComponent } from './information-pages/disclaimer/disclaimer.component';
import { BecomeProviderComponent } from './information-pages/become-provider/become-provider.component';
import { InformedConsentComponent } from './information-pages/informed-consent/informed-consent.component';
import { TermsOfUseComponent } from './information-pages/terms-of-use/terms-of-use.component';
import { WhoWeAreComponent } from './information-pages/company/who-we-are/who-we-are.component';
import { ExecutiveTeamComponent } from './information-pages/company/executive-team/executive-team.component';
import { PartnersComponent } from './information-pages/company/partners/partners.component';
import { BlogComponent } from './information-pages/company/blog/blog.component';
import { HospitalAndClinicsComponent } from './information-pages/businesses/hospital-and-clinics/hospital-and-clinics.component';
import { GovernmentComponent } from './information-pages/businesses/government/government.component';
import { HowItWorksComponent } from './information-pages/patients/how-it-works/how-it-works.component';
import { FrequentlyAskedQuestionsComponent } from './information-pages/patients/frequently-asked-questions/frequently-asked-questions.component';
import { WorkInProgressComponent } from './information-pages/work-in-progress/work-in-progress.component';
import { PrivateTransportCompaniesComponent } from './information-pages/businesses/private-transport-companies/private-transport-companies.component';
import { HotelsRentalsToursComponent } from './information-pages/businesses/hotels-rentals-tours/hotels-rentals-tours.component';
import { WhatWeTreatComponent } from './information-pages/patients/what-we-treat/what-we-treat.component';
import { CalenderComponent } from './calender/calender.component';
import { OurProvidersComponent } from './information-pages/patients/our-providers/our-providers.component';
import { PharmaciesLabsComponent } from './information-pages/businesses/pharmacies-labs/pharmacies-labs.component';
import { CovidComponent } from './information-pages/patients/covid/covid.component';
import { RouteReuseStrategy } from '@angular/router';
import { InsuranceComponent } from './information-pages/businesses/insurance/insurance.component';
import { AgoraLoginComponent } from './agora-login/agora-login.component';
import { TravelComponent } from './information-pages/travel/travel.component';
import { SharedModule } from './shared/shared.module';
import { KVKKComponent } from './information-pages/kvkk/kvkk.component';

import {
  NgxZendeskWebwidgetModule,
  NgxZendeskWebwidgetConfig,
} from 'ngx-zendesk-webwidget';
import { ThyComponent } from './information-pages/company/thy/thy.component';
import { SolutionsComponent } from './information-pages/solutions/solutions.component';

import { registerLocaleData } from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import localeEn from '@angular/common/locales/en';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

registerLocaleData(localeTr, 'tr');
registerLocaleData(localeEn, 'en');

const config: SocketIoConfig = {
  url: environment.webrtcSocketConfig.url,
  options: {},
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  accountUrl = 'veradoctors.zendesk.com';
  callback(zE) {
    // You can call every command you want in here
    zE('webWidget', 'hide');
    zE('webWidget', 'show');
    zE('webWidget', 'activate');
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PrivacyPolicyComponent,
    DisclaimerComponent,
    BecomeProviderComponent,
    InformedConsentComponent,
    TermsOfUseComponent,
    WhoWeAreComponent,
    KVKKComponent,
    ExecutiveTeamComponent,
    PartnersComponent,
    BlogComponent,
    HospitalAndClinicsComponent,
    GovernmentComponent,
    HowItWorksComponent,
    FrequentlyAskedQuestionsComponent,
    WorkInProgressComponent,
    PrivateTransportCompaniesComponent,
    HotelsRentalsToursComponent,
    WhatWeTreatComponent,
    CalenderComponent,
    OurProvidersComponent,
    PharmaciesLabsComponent,
    CovidComponent,
    InsuranceComponent,
    AgoraLoginComponent,
    TravelComponent,
    ThyComponent,
    CookieConsentComponent,
  ],
  imports: [
    JwtModule.forRoot({
      config: {
        tokenGetter: function tokenGetter() {
          if (localStorage.getItem('xauth')) {
            return localStorage.getItem('xauth');
          }
        },
      },
    }),

    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    NgMultiSelectDropDownModule.forRoot(),

    ReactiveFormsModule,
    SocialLoginModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SlickCarouselModule,
    ToastrModule.forRoot(),

    HttpClientInMemoryWebApiModule.forRoot(DataService, {
      delay: 1000,
      passThruUnknownUrl: true,
    }),
    BrowserAnimationsModule,
    FormsModule,
    NgbModule,
    Daterangepicker,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    NgxStripeModule.forRoot(
      'pk_test_51J13AZEkiMNbqcbTs2SpACsai7BRqEUC3unY3eBTYewnZgN7NcdGnqUMgUNI9LElPJcUb9rZ6kRPSlbiHsZkz5aU00ASwRmyWy'
    ),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    AngularFireModule.initializeApp(configFirebase),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    // AngularFireStorageModule // storage

    NgxIntlTelInputModule,

    SocketIoModule.forRoot(config),

    QuillModule.forRoot(),
    DataTablesModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    SharedModule,
    CommonModule,
  ],
  //providers: [],
  providers: [
    // app ID: 4282827548452431
    // app secret: 80ab7fe755af61d20e49490272dce633

    // clientid: 78700387400-egihij5ramqsmgh4cnqia5vu4pjnado1.apps.googleusercontent.com
    // webclientsecret: 432CrdVEN-hrD1C6r5558Zdj

    RoleGuardService,
    AuthService,
    JwtHelperService,
    LanguageService,
    /*MessagingService,*/ AsyncPipe,
    DatePipe,
    {
      provide: 'SocialAuthServiceConfig',

      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            // provider: new GoogleLoginProvider('78700387400-egihij5ramqsmgh4cnqia5vu4pjnado1.apps.googleusercontent.com')
            provider: new GoogleLoginProvider('vera-dev-563b2'),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            // provider: new FacebookLoginProvider('931898134019764')
            provider: new FacebookLoginProvider('308500938147914'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'tr-TR' },
    { provide: LOCALE_ID, useValue: 'en-US' },
  ],

  exports: [NgxZendeskWebwidgetModule],

  bootstrap: [AppComponent],
})
export class AppModule {}
