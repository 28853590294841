<div class="container">
  <div class="row">
    <div class="card my-5 mx-2">
      <h2 class="text-center hiw-title">
        {{ "VERA PREMİUM CARE A.Ş" | translate }}
      </h2>
      <h1 class="text-center hiw-title my-3">
        {{ "Privacy Policy" | translate }}
      </h1>
      <div class="card-body desc" style="color: #000; text-align: justify">
        <h3>{{ "Children Under the Age of 18." | translate }}</h3>
        <p>
          {{
            "Our Website and Application are not intended for children under the age of 18 without parental consent."
              | translate
          }}
        </p>
        <p>
          {{
            "I. Kayıt sürecinde sizden istediğimiz bilgiler. Gizlilik sözleşmemizde tanımlanan durumların dışında
          kişisel olarak tanımlanabilir olan bilgilerinizi kullanmıyor veya paylaşmıyoruz."
              | translate
          }}
        </p>
        <p>
          {{
            "II. VERA ücretli ya da ücretsiz servislerimizden yararlanırken kullanıcıların kişisel bilgilerini korur."
              | translate
          }}
        </p>
        <p>
          {{
            "III. Kullanıcılardan topladığımız bilgilerin nasıl toplanıldığı, depolandığı açıklanır. VERA Doctors kişisel
          bilgilerinizi depolarken endüstri seviyesindeki SSL şifreleme ve uçtan uca şifreleme yöntemini kullanır."
              | translate
          }}
        </p>
        <h3>{{ "Neden bu sözleşmeyi okumalıyım?" | translate }}</h3>

        <p>
          {{
            "If you are under the age of eighteen (18) and wish to create an account with VERA Doctors, your parent or
          legal guardian must create the account, submit your personal information, and agree to this privacy policy on
          your behalf. If you are under the age of 13, you may only use our services and access our Website and
          Application with the supervision and consent of your parents or legal guardians, including the Provider
          consultation services. If we learn that we have collected personal information from someone under the age of
          13 that was not provided with the supervision and consent of the minor’s parents or legal guardian, we will
          promptly delete that information. If you believe we have impermissibly collected personal information from
          someone under the age of 13, please contact us using the information below."
              | translate
          }}
        </p>
        <p>
          {{
            "VERA Doctors kişisel gizliliğinizi nasıl farklı ele alır?"
              | translate
          }}
        </p>
        <p>
          {{
            "Bu gizlilik sözleşmesi hangi bilgileri bizle paylaşabileceğinizi, bu bilgilerin nasıl saklandığını, bu
          bilgileri nasıl kullandığımızı ve hangi bilgileri VERA Doctors kullanıcıları/ uzmanlarının görebileceğini
          açıklar." | translate
          }}
        </p>
        <h3>{{ "GİRİŞ" | translate }}</h3>
        <p>
          {{
            "VERA Doctors sizin sağlık verilerinizi güvenilir bir şekilde saklar. Dilediğiniz sürece bu bilgileri başka
          kullanıcılarla paylaşmanıza yardımcı olur. Bunların hepsi daha mutlu ve daha sağlıklı bir yaşam sürdürmeniz
          için vardır." | translate
          }}
        </p>
        <h4>{{ "Gizlilik ve Anonimlik" | translate }}</h4>
        <p>{{ "VERA Doctors anonim midir?" | translate }}</p>
        <p>
          {{
            "Sizin VERA Doctors kullanımınız anonimdir. Kişisel olarak tanımlanabilir bilgileriniz (adınız, yaşınız,
          e-mail adresiniz gibi) başkaları tarafından görüntülenemez."
              | translate
          }}
        </p>
        <h3>
          {{
            "VERA Doctors profil paylaşma servisleri anonim midir?" | translate
          }}
        </h3>
        <p>
          {{
            "VERA Doctors uygulaması üzerinden bir yakınınız veya doktorunuz ile profilinizi paylaştığınız durumunda
          kişisel bilgileriniz (sağlık durumunuz gibi bilgiler dahil olmakla birlikte) ve iletişim bilgileriniz
          seçtiğiniz ve yetki verdiğiniz kullanıcılar tarafından görüntülenebilir."
              | translate
          }}
        </p>
        <h3>{{ "Güvenlik" | translate }}</h3>
        <p>{{ "VERA Doctors ve servisleri güvenli midir?" | translate }}</p>

        <h2>
          {{
            "Information We Collect About You and How We Collect It."
              | translate
          }}
        </h2>
        <p>
          {{
            "We collect different types of information about you, including information that may directly identify you,
          information that is about you but individually does not personally identify you, and information that we
          combine with our other users. This includes information that we collect directly from you or through automated
          collection technologies." | translate
          }}
        </p>
        <h2>
          <u>{{ "Generally" | translate }}</u>
        </h2>
        <h3 class="pb-2">{{ "Kişisel Bilgiler" | translate }}</h3>
        <h3 class="pb-2">{{ "Kişisel Profiliniz" | translate }}</h3>
        <h3 class="pb-2">
          {{ "Profilimde nasıl bilgiler depolayabilirim?" | translate }}
        </h3>
        <p>
          {{
            "We collect several types of information from and about users of our Website and Application (collectively,
          “Personal Information”) specifically, information:" | translate
          }}
        </p>
        <h3>{{ "Profilimdeki bilgiler nasıl kullanılır?" | translate }}</h3>
        <ul *ngIf="languageSelect == 'en'">
          <li>
            {{
              "by which you may be personally identified, such as name, address, e-mail address, home, work, and
            mobile telephone numbers, date of birth, credit or debit card number (for payment purposes only), audio
            (including
            recordings and transcripts), images and videos of you, gender, your medical history, health insurance
            subscriber information, and health information;" | translate
            }}
          </li>
          <li>
            {{
              "about your Internet connection, the equipment you use to access our Website or use our Application and
            usage details, such as traffic data, logs, referring/exit pages, date and time of your visit to our Website
            or use of our Application, error information, clickstream data, and other communication data and the
            resources that you access and use on the Website or through our Application."
                | translate
            }}
          </li>
        </ul>
        <p>
          {{
            "VERA Doctors kişisel bilgilerinizi sağlığınızı daha iyi takip etmeniz, sağlık konusunda bilinçlenmeniz ve
          sağlığınızla ilgili bilgileri paylaşabilmeniz amacıyla depolar."
              | translate
          }}
        </p>
        <h3>{{ "Profilimi düzenleyebilir miyim?" | translate }}</h3>
        <p>
          {{
            "Profilinizde görünen bilgileri düzenleyebilir, değiştirebilir, ekleyebilir ya da silebilirsiniz. Yalnızca
          kayıt ve uygulama kullanımı sırasında zorunlu olarak paylaşmanız gereken bilgileri(isim gibi) silmeniz mümkün
          değildir." | translate
          }}
        </p>
        <p>
          <span style="font-weight: 550">{{
            "We collect this information:" | translate
          }}</span>
        </p>
        <ul *ngIf="languageSelect == 'en'">
          <li>
            {{ "directly from you when you provide it to us" | translate }};
          </li>
          <li>
            {{
              "automatically as you navigate through the Website or use our
            Application. Information collected automatically may include usage details, IP addresses, and information
            collected through cookies and other tracking technologies; and"
                | translate
            }}
          </li>
          <li>
            {{
              "from third parties, for example, our business partners."
                | translate
            }}
          </li>
        </ul>
        <h3>
          {{
            "Diğer kullanıcılar profilimi ve içerisindeki sağlık bilgilerimi görüntüleyebilir mi?"
              | translate
          }}
        </h3>
        <h2>
          <u>{{ "Information You Provide to Us" | translate }}</u>
        </h2>
        <p>
          {{
            "The information we collect on or through our Website or through our Application is:"
              | translate
          }}
        </p>
        <ul *ngIf="languageSelect == 'en'">
          <li>
            {{
              "information that you provide by filling in forms on our Website or the Application videos, images, and
            recordings when interacting with a Provider on our Website or the Application. This includes information
            provided at the time of registering to use our Website or Application as well as information provided when
            using our Provider consultation services, purchasing products, or requesting further services. We may also
            ask you for information when you report a problem with our Website or Application"
                | translate
            }};
          </li>
          <li>
            {{
              "records and copies of your correspondence (including email addresses), if you contact us; and"
                | translate
            }}
          </li>
          <li>
            {{
              "details of transactions you carry out through our Website or through the Application and of the
            fulfillment of your orders. You may be required to provide financial information before placing an order
            through our website or Application." | translate
            }}
          </li>
        </ul>
        <h3>{{ "Profilim güvende midir?" | translate }}</h3>
        <p>
          {{
            "Hesabınız, profil bilgileriniz güvenli bir şekilde şifrelenir, şifreniz korunur."
              | translate
          }}
        </p>
        <h3>{{ "Kişisel olarak tanılabilir bilgiler." | translate }}</h3>
        <p>
          {{
            "Kişisel olarak tanılabilir bilgiler kimliğinizi belirleyen bilgilerdir (tam adınız ve e-mail adresiniz
          gibi)." | translate
          }}
        </p>
        <h3>
          {{
            "Kişisel olarak tanılabilir bilgilerimi nasıl güvende ve gizli tutuyorsunuz?"
              | translate
          }}
        </h3>
        <p>
          {{
            "You also may provide information to be published or displayed (hereinafter, “posted”) on public areas of
          the Website or Application or transmitted to other users of the Website or Application or third parties
          (collectively, “User Contributions”). Your User Contributions are posted on and transmitted to others at your
          own risk. Although we limit access to certain pages, please be aware that no security measures are perfect or
          impenetrable. Additionally, we cannot control the actions of other users of the Website and Application with
          whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User
          Contributions will not be viewed by unauthorized persons." | translate
          }}
        </p>
        <h3>
          {{
            "Kişisel olarak tanılabilir bilgilerim VERA Doctors üzerinden görüntülenebilir mi?"
              | translate
          }}
        </h3>
        <p>
          {{
            "The audio portion of your medical telehealth visit (including audio from visits that include video) is
          recorded for purposes that may include treatment, quality, improvement of health status, customer and patient
          experience, customer and patient engagement and/or behavior modification, peer review, payment, efficiency,
          cost-effectiveness and/or other purposes relating to operations and provision of telehealth services.
          Behavioral health visits are not recorded. By using the telehealth services available through our Website and
          Application you specifically consent to the recording of the medical visit."
              | translate
          }}
        </p>
        <h3>
          {{
            "Kişisel olarak tanılabilir bilgilerimi nasıl kullanıyorsunuz?"
              | translate
          }}
        </h3>
        <h2>
          <u>{{
            "Information We Collect Through Automatic Data Collection Technologies"
              | translate
          }}</u>
        </h2>
        <p>
          {{
            "As you navigate through and interact with our Website and Application, we may use automatic data collection
          technologies to collect certain information about your equipment, browsing actions, and patterns,
          specifically:" | translate
          }}
        </p>
        <ul *ngIf="languageSelect == 'en'">
          <li>
            {{
              "details of your access to and use of the Application and Website, including traffic data, location
            data,
            logs, and other communication data and the resources that the end-user accesses and uses on or through the
            Application and Website" | translate
            }};
          </li>
          <li>
            {{
              "information about your mobile device and internet connection, including the device’s unique device
            identifier, IP address, operating system, browser type, mobile network information, and the device’s
            telephone number" | translate
            }};
          </li>
          <li>
            {{
              "information stored on your mobile device, including in other applications. This may include,
            photographs,
            audio and video clips, personal contacts, and health information. In particular, we may collect information
            through your Health application. This data will be used only to provide and improve our services, and will
            not be used or shared with third parties for marketing purposes; or"
                | translate
            }}
          </li>
          <li>
            {{
              "real-time information about the location of your device."
                | translate
            }}
          </li>
        </ul>
        <h3>{{ "Veri Saklama Politikası" | translate }}</h3>
        <p>
          {{
            "The information we collect automatically may include Personal Information or we may maintain it or
          associate it with Personal Information we collect in other ways or receive from third parties. It helps us to
          improve
          our Website and Application and to deliver a better and more personalized service by enabling us to:"
              | translate
          }}
        </p>
        <ul *ngIf="languageSelect == 'en'">
          <li>
            {{ "estimate our audience size and usage patterns" | translate }};
          </li>
          <li>
            {{
              "forecast future needs, functions, and uses of our services, the Website, and the Application"
                | translate
            }};
          </li>
          <li>
            {{
              "better understand user satisfaction levels and experiences"
                | translate
            }};
          </li>
          <li>
            {{
              "store information about your preferences, allowing us to customize our Website and our Application
            according to your individual interests" | translate
            }};
          </li>
          <li>
            {{
              "recognize you when you return to our Website and our Application"
                | translate
            }};
          </li>
          <li>
            {{
              "in other ways to improve your experience and the quality of our Website, Application, and services."
                | translate
            }}
          </li>
        </ul>
        <h3>{{ "İletişim Kanalları" | translate }}</h3>
        <p>
          {{ "E-mail’ler, kısa mesajlar ve mobil bildirimler." | translate }}
        </p>
        <p>
          <span style="font-weight: 550">{{
            "The technologies we use for this automatic data collection may include:"
              | translate
          }}</span>
        </p>
        <ul *ngIf="languageSelect == 'en'">
          <li>
            {{
              "Cookies (or browser cookies). We and our service providers may use cookies, pixel tags, web beacons,
            and other technologies to receive and store certain types of information whenever you interact with our
            Website
            and Application through your computer or mobile device. A “cookie” is a small piece of data sent from a
            website and stored on the user’s computer by the user’s web browser while the user is browsing. On your
            computer, you may refuse to accept browser Cookies by activating the appropriate setting on your browser,
            and you may have similar capabilities on your mobile device in the preferences for your operating system or
            browser. However, if you select this setting you may be unable to access certain parts of our Website or use
            certain parts of our Application. Unless you have adjusted your browser or operating system setting so that
            it will refuse cookies, our system will issue cookies when you direct your browser to our Website or use our
            Application." | translate
            }}
          </li>
          <li>
            {{
              "Google Analytics. We use Google Analytics, a web analytics service provided by Google, Inc.
            (“Google”)to collect certain information relating to your use of the Website. Google Analytics uses cookies
            to help the
            Website analyze how users use the site. You can find out more about how Google uses data when you visit our
            Website by visiting “How Google uses data when you use our partners’ sites or apps”, (located at
            www.google.com/policies/privacy/partners/). We may also use Google Analytics Advertising Features or other
            advertising networks to provide you with interest-based advertising based on your online activity. For more
            information regarding Google Analytics please visit Google’s website and pages that describe Google
            Analytics, such as www.google.com/analytics/learn/privacy.html."
                | translate
            }}
          </li>
          <li>
            {{
              "Facebook. We use Facebook Pixel, a web analytics and advertising service provided by Facebook
            Inc.(“Facebook”) on our Website and Application. With its help, we can keep track of what users do after
            they see or click on a Facebook advertisement, keep track of users who access our Website and Application or
            advertisements from different devices, and better provide advertisements to our target audiences. The data
            from Facebook Pixel is also saved and processed by Facebook. Facebook can connect this data with your
            Facebook account and use it for its own and other's advertising purposes, in accordance with Facebook’s Data
            Policy which can be found at https://www.facebook.com/about/privacy/. Please click here if you would like to
            withdraw your consent for use of your data with Facebook Pixel
            https://www.facebook.com/settings/?tab=ads#_=" | translate
            }}
          </li>
        </ul>
        <h2>{{ "How We Use Your Information?" | translate }}</h2>
        <h3>
          {{
            "VERA Doctors ya da üçüncül şahıslardan spam alacak mıyım?"
              | translate
          }}
        </h3>
        <p>
          {{
            "We use your Personal Information for various purposes described below, including to:"
              | translate
          }}
        </p>
        <ul *ngIf="languageSelect == 'en'">
          <li>
            {{ "provide our Website or Application to you" | translate }};
          </li>
          <li>{{ "provide services to you" | translate }};</li>
          <li>
            {{
              "provide you with information you request from us" | translate
            }};
          </li>
          <li>
            {{ "enforce our rights arising from contracts" | translate }};
          </li>
          <li>{{ "notify you about changes; and" | translate }}</li>
          <li>
            {{ "provide you with notices about your account." | translate }}
          </li>
        </ul>
        <h3>{{ "E-mail adresim ne için kullanılıyor?" | translate }}</h3>
        <p>
          {{
            "We use information that we collect about you or that you provide to us, including any Personal
          Information:" | translate
          }}
        </p>
        <ul *ngIf="languageSelect == 'en'">
          <li>
            {{
              "to provide and improve our Website, Application, products, and services. For example, we may convert
            our
            recordings to text and perform machine learning on data we receive"
                | translate
            }};
          </li>
          <li>
            {{
              "to provide you with information, products, or services that you request from us or that may be of
            interest
            to you" | translate
            }};
          </li>
          <li>
            {{
              "for purposes of treatment, quality, improvement of health status, customer and patient experience,
            customer and patient engagement and/or behavior modification, peer review, payment, efficiency,
            cost-effectiveness, and/or other purposes relating to operations and provision of telehealth services"
                | translate
            }};
          </li>
          <li>
            {{
              "to process, fulfill, support, and administer transactions and orders for products and services ordered
            by
            you" | translate
            }};
          </li>
          <li>
            {{
              "to provide you with notices about your VERA Doctors account"
                | translate
            }};
          </li>
          <li>{{ "to contact you in response to a request" | translate }};</li>
          <li>{{ "to administer surveys" | translate }};</li>
          <li>
            {{
              "to fulfill any other purpose for which you provide it"
                | translate
            }};
          </li>
          <li>
            {{
              "to carry out our obligations and enforce our rights arising from any contracts entered into between you
            and us or between us and health plans or other benefit programs, including for billing and collection"
                | translate
            }};
          </li>
          <li>
            {{
              "to notify you about changes to our Website, our Application, or any products or services we offer or
            provide through them" | translate
            }};
          </li>
          <li>
            {{
              "in any other way we may describe when you provide the information; and"
                | translate
            }}
          </li>
          <li>{{ "for any other purpose with your consent" | translate }}.</li>
        </ul>
        <h3>
          {{
            "E-mail adresim ve telefon numaram gizli tutulur mu?" | translate
          }}
        </h3>
        <p>
          {{
            "We may also use your information to contact you about goods and services that may be of interest to you,
          including through newsletters. If you wish to opt-out of receiving such communications, you may do so at any
          time by clicking unsubscribe at the bottom of these communications."
              | translate
          }}
        </p>
        <h2>{{ "Disclosure of Your Information" | translate }}</h2>
        <h3>
          {{ "İletişim bilgilerimi nasıl değiştirebilirim?" | translate }}
        </h3>
        <p>
          {{
            "We do not share, sell, or otherwise disclose your Personal Information for purposes other than those
          outlined in this Privacy Policy. We may disclose your Personal Information to a few third parties, including:"
              | translate
          }}
        </p>
        <ul *ngIf="languageSelect == 'en'">
          <li>
            {{
              "our affiliates and third-party service providers that we use to support our business"
                | translate
            }};
          </li>
          <li>
            {{
              "to a company we merge, acquire, or that buys us, or in the event of a change in the structure of our
            company of any form" | translate
            }};
          </li>
          <li>{{ "to comply with our legal obligations" | translate }};</li>
          <li>{{ "to enforce our rights; and" | translate }}</li>
          <li>{{ "with your consent" | translate }}.</li>
        </ul>
        <h3>{{ "Bildirimler" | translate }}</h3>
        <h3>{{ "Bildirim nedir?" | translate }}</h3>
        <p>
          {{
            "We do not share or otherwise disclose your Personal Information for purposes other than those outlined in
          this Privacy Policy." | translate
          }}
        </p>
        <h3>{{ "Bir bildirimim olduğunu nasıl bilebilirim?" | translate }}</h3>
        <p>
          {{
            "VERADoctors’a kayıt olmanız durumunda bildirimleri görebileceksiniz. VERA Doctors size e-mail, SMS veya
          anlık iletiler gönderebilir; size hesabınızla ilgili hatırlatmalar yapabilir, VERA Doctors üzerinden bir
          hatırlatmanız olduğunu bildirebilir. Bu bildirimleri tercihlerinize göre sesli bildirim olarak alabilirsiniz."
              | translate
          }}
        </p>
        <h3>
          {{ "Bildirim tercihlerimi nasıl değiştirebilirim?" | translate }}
        </h3>
        <p>
          {{
            "Telefonunuzun ayarlar bölümünden bize tanımak istediğiniz hakları düzenlemeniz mümkün. Bildirim almak
          istemiyorsanız, ayarlar bölümünden gerekli değişiklikleri yapabilirsiniz. Ayrıca uygulama içerisindeki ayarlar
          bölümünden bildirim alma seçeneklerinizi yönetebilirsiniz."
              | translate
          }}
        </p>
        <h3>{{ "Kayıt" | translate }}</h3>
        <h3>
          {{ "Kayıt olmak için ne gibi bilgiler gereklidir?" | translate }}
        </h3>
        <ul *ngIf="languageSelect == 'en'">
          <li>
            {{
              "if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of
            VERA Doctors, our customers, or others. This includes exchanging information with other companies and
            organizations for the purposes of fraud protection and credit risk reduction."
                | translate
            }}
          </li>
        </ul>
        <p>
          {{
            "VERADoctors’dan yararlanabilmek için bir kullanıcı hesabı oluşturmanız gerekir. Hesap oluştururken sizden
          yalnızca kullanıcı adı tanımlamanız istenir. Bunun dışında sağlık durumunuz ile ilgili bilgileri verip
          vermemek," | translate
          }}
          <span class="text-danger font-italic">{{
            "e-mail, yaş, cinsiyet bilgilerini girmek tamamen kullanıcının
            tercihine bağlıdır." | translate
          }}</span>
        </p>

        <h3>
          {{ "Neden doğum tarihim veya yaşıma ihtiyacınız var?" | translate }}
        </h3>
        <p>
          {{
            "Sağlık verileri yaş ile yakından ilişkilidir. Size daha iyi bir hizmet sağlayabilmek için yaşınız gibi
          bilgilere ihtiyaç duyarız. Yaş gibi kişisel bilgileriniz siz seçmediğiniz sürece diğer kullanıcılar tarafından
          görüntülenemez. Ücretli olarak VERA Doctors servislerinden yararlanmanız durumunda yaşınız gibi kişisel
          bilgileriniz seçilen diğer kullanıcılar tarafından görüntülenebilir."
              | translate
          }}
        </p>

        <h3>{{ "Hesabıma hangi ek bilgileri ekleyebilirim?" | translate }}</h3>
        <p>
          {{
            "Profilinize ek olarak sağlık bilgilerinizi ekleyebilirsiniz."
              | translate
          }}
        </p>
        <h3>{{ "Hesap etkinliği sonlandırma." | translate }}</h3>
        <h3>
          {{ "Hesabımın etkinliğini nasıl sonlandırabilirim?" | translate }}
        </h3>
        <p>
          {{
            "Uygulama içerisinden hesabınızın etkinliğini sonlandırabilirsiniz. Hesabınızı sonlandırmanız durumunda,
          işlemin başarıyla tamamlandığını bildiren bir e-posta alırsınız."
              | translate
          }}
        </p>
        <h3>{{ "Bilgi kullanımı." | translate }}</h3>
        <h3>
          {{ "VERA Doctors kişisel bilgilerimi nasıl kullanır?" | translate }}
        </h3>
        <p>
          {{
            "VERA Doctors kullanımınız dolayısıyla edindiğimiz kişisel bilgilerinizi gizlilik sözleşmesinde açıklandığı
          şekilde, size mevcut ve gelecek servislerimiz hakkında bilgi sağlamak amacıyla kullanırız. Bu gizlilik
          sözleşmesi kişisel bilgilerinizi nasıl kullandığımızı açıklar. Kişisel bilgilerinizi şu gibi durumlarda
          kullanabiliriz:" | translate
          }}
        </p>
        <ul *ngIf="languageSelect == 'tr'">
          <li>
            {{
              "Güncellemeler ve bildirimler dahil olmakla birlikte size VERA Doctors hakkında önemli bilgiler sağlar."
                | translate
            }}
          </li>
          <li>
            {{
              "Size e-mail, SMS, bildirim ve benzeri uyarıcılar gönderebilir."
                | translate
            }}
          </li>
          <li>
            {{
              "Siz izin verdiğiniz ve ilgili yetkiliyi tanımladığınız takdirde doktorunuz ve sizinle ilgilenen
            hemşireniz arasında bir iletişim ağı kurabilir.Ailenize veya sizin sağlık çizelgenizi takip etmek isteyen
            yakınlarınıza durumunuzla ilgili bilgilendirmeler yapabilir."
                | translate
            }}
          </li>
        </ul>
        <h3>
          {{
            "Kişisel olarak tanınabilir bilgiler satıyor musunuz?" | translate
          }}
        </h3>
        <p>
          {{
            "Hayır. Kimliğiniz bizimle güvence altındadır ve satılık değildir. Kişisel olarak tanınabilir bilgiler
          satmıyoruz." | translate
          }}
        </p>
        <h3>{{ "Üçüncül Şahıslar." | translate }}</h3>
        <h3>
          {{
            "VERA Doctors olmayan servis sağlayıcısı nedir ve VERA Doctors bu kişilerle ne gibi bilgiler paylaşır?"
              | translate
          }}
        </h3>
        <p>
          {{
            "İzniniz olmadığı sürece VERA Doctors size ait olan bilgileri bir başkasıyla paylaşmaz. VERA Doctors profil
          paylaşma servislerini kullanarak adınızın ve profilinizde bulunan sağlık bilgilerinizin seçtiğiniz
          kullanıcılar tarafından görüntülenmesine izin vermiş olursunuz."
              | translate
          }}
        </p>
        <h3>
          {{
            "Üçüncül şahıslara kişisel olarak tanınabilir bilgileriniz verilir mi?"
              | translate
          }}
        </h3>
        <p>
          {{
            "VERA Doctors veya VERA Doctors uzmanları sizin ilginizi çekecek veya size faydası olacak dış servis
          sağlayıcıları hakkında bilgiler verebilir. VERA Doctors bu dış servislere sizin hakkınızda kişisel olarak
          tanınabilir bilgiler vermez." | translate
          }}
        </p>
        <h3>
          {{
            "Bu gizlilik sözleşmesi VERADoctors’dan olmayan sağlayıcılar aracılığıyla paylaştığım bilgileri kontrol
          eder mi?" | translate
          }}
        </h3>
        <p>
          {{
            "Hayır. Bu gizlilik sözleşmesi VERADoctors’a bağlı olmayan diğer servisler ve sağlayıcılar için geçerli
          değildir (örneğin bir başka siteye giden bir link). Başka servisleri kullanmadan önce ilgili kullanım
          şartlarını ve gizlilik sözleşmelerini okumalısınız." | translate
          }}
        </p>
        <h3>{{ "İzleme Teknolojileri ve Çerezler" | translate }}</h3>
        <p>
          {{
            "Çerez, bir ziyaretçinin tarayıcısına gönderilen küçük bir metin parçasıdır. Tarayıcı, bu metin parçasını,
          bu ziyaretçi geri döndüğünde, kaynak ziyaretçinin cihazına sağlar. VERA Doctors deneyiminizi
          kişiselleştirmenize yardımcı olmak için çerezleri kullanıyoruz."
              | translate
          }}
        </p>
        <p>
          {{
            "Ayarlarınızı ve özelleştirmelerinizi kaydetmenize yardımcı olmak için 'kalıcı' bir çerez kullanılabilir.
          Ayrıca, Siteye giriş yaparsanız, böyle bir çerez sizi geçerli bir kullanıcı olarak tanımak için
          kullanılacaktır, bu nedenle Siteyi her kullandığınızda giriş yapmanız gerekmeyecektir."
              | translate
          }}
        </p>
        <p>
          {{
            "Çoğu Web tarayıcısı tanımlama bilgilerini otomatik olarak kabul eder, ancak güvenlik ayarlarını
          değiştirmenize izin verir, böylece duruma göre tanımlama bilgilerini onaylayabilir veya reddedebilir veya tüm
          tanımlama bilgilerini reddedebilirsiniz. İnternet tarayıcınızın “yardım” bölümünde sağlanan yönergeleri
          izleyerek web tarayıcınızı çerezleri kaldıracak şekilde yapılandırabilirsiniz."
              | translate
          }}
        </p>
        <ul *ngIf="languageSelect == 'tr'">
          <li class="text-info"><u>Safari</u></li>
          <li class="text-info"><u>Opera</u></li>
          <li class="text-info"><u>Firefox</u></li>
          <li class="text-info"><u>Google chrome</u></li>
          <li class="text-info"><u>Internet explorer</u></li>
        </ul>
        <p>
          {{
            "Ayrıca, mevcut çerezleri istediğiniz zaman silmekte özgürsünüz. Sitemizden çerezleri siler veya devre dışı
          bırakırsanız, Sitenin bazı bölümleri veya işlevleri sizin için düzgün çalışmayabilir."
              | translate
          }}
        </p>
        <p>
          {{
            "Ayrıca Hizmetin kullanımı hakkında bilgi toplamak için “Google Analytics” kullanıyoruz. Google Analytics,
          kullanıcıların Hizmeti ne sıklıkla ziyaret ettikleri, bunu yaparken hangi sayfaları ziyaret ettikleri ve
          Hizmete gelmeden önce başka hangi siteleri kullandıkları gibi bilgileri toplar. Google Analytics, adınız veya
          diğer tanımlayıcı bilgiler yerine yalnızca Hizmeti kullandığınız tarihte size atanan IP adresinin yanı sıra
          işletim sisteminiz, diliniz ve Hizmeti kullanımınıza ilişkin bilgileri toplar. Google Analytics kullanımı
          yoluyla toplanan bilgileri Kişisel Bilgilerle birleştirmeyiz. Google Analytics'ten aldığımız bilgileri
          yalnızca Hizmeti geliştirmek için kullanırız. Google'ın, Hizmeti kullanımınız hakkında Google Analytics
          tarafından toplanan bilgileri kullanma ve paylaşma yeteneği,"
              | translate
          }}
          <a
            href="https://marketingplatform.google.com/about/analytics/terms/us/"
            style="color: blue"
            >{{ "burada" | translate }}</a
          >
          {{ "bulunan Google Analytics Kullanım Şartları ve " | translate }}
          <a href="https://policies.google.com/privacy" style="color: blue">{{
            "burada" | translate
          }}</a>
          {{
            "bulunan Google Gizlilik Politikası ile sınırlandırılmıştır."
              | translate
          }}
        </p>

        <p>
          {{
            "Ayrıca Hizmetin kullanımı hakkında bilgi toplamak için 'Branch IO' kullanırız. Branch, bize yardımcı olmak
          için kurulu uygulama adları ve paket adları hakkında bilgi toplar. Bu kullanım"
              | translate
          }}
          <a
            href="https://branch.io/policies/privacy-policy/"
            style="color: blue"
            >{{ "burada" | translate }}</a
          >
          {{
            "bulunan Branch IO Gizlilik Politikası ile sınırlandırılmıştır."
              | translate
          }}
        </p>
        <p>{{ "Bize ulaşın" | translate }}</p>
        <p>{{ "Size nasıl ulaşabilirim?" | translate }}</p>
        <p>
          {{
            "Talep ve şikayetlerinizi contact@veradoctors.com e-mail adresinden bize iletebilirsiniz."
              | translate
          }}
        </p>
      </div>
    </div>
  </div>
</div>
