// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // webrtcSocketConfig: { url: 'https://doc-rtc.myeduroom.com', options: {} },
  // webrtcSocketConfig: { url: 'https://vera-backend-rtc-test.myeduroom.com' , options: {} },  // test
  webrtcSocketConfig: {
    url: 'https://vera-backend-rtc-test.veradoctors.com',
    options: {},
  }, // gerçek ortam endpoint i

  checkoutCompleteUrl: 'https://doc-call.myeduroom.com/patients/success',

  // developmentUrl: "https://doc.myeduroom.com",
  // developmentUrl: "https://vera-backend-test.myeduroom.com",
  developmentUrl: 'https://vera-backend-test.veradoctors.com',

  // callCenterSocketConfig: { url: 'https://doc-callcenter.myeduroom.com', options: {} },  // development
  // callCenterSocketConfig: { url: 'https://vera-callcenter-test.myeduroom.com', options: {} },  // test
  callCenterSocketConfig: {
    url: 'https://vera-callcenter-test.veradoctors.com',
    options: {},
  }, // gerçek ortam endpoint i

  firebase: {
    apiKey: 'AIzaSyDPHtd3wNMnt3ng25Dr7ZFtX2pJpjX6s88',
    authDomain: 'vera-dev-563b2.firebaseapp.com',
    projectId: 'vera-dev-563b2',
    storageBucket: 'vera-dev-563b2.appspot.com',
    messagingSenderId: '78700387400',
    appId: '1:78700387400:web:486b9ee932bea8107ecc7d',
    measurementId: 'G-EHCWGRVZ78',
  },

  // siteUrl: "https://doc-call.myeduroom.com",
  // siteUrl: "https://www.veradoctors.com",
  siteUrl: 'https://www.veradoctors.com', // gerçek ortam endpoint i
  yahUrl: 'https://www.yukunuathafifle.com', // Obezite yah web site url

  // socketUrl: "https://doc-callcenter.myeduroom.com",
  // socketUrl: "https://vera-callcenter-test.myeduroom.com",
  socketUrl: 'https://vera-callcenter-test.veradoctors.com', // gerçek ortam endpoint i

  // peerStunUrl: "stun:stun.myeduroom.com:3478",
  peerStunUrl: 'stun:stun.veradoctors.com:3478',

  // peerTurnUrl: "turn:turn.myeduroom.com:3478",
  peerTurnUrl: 'turn:turn.veradoctors.com:3478',

  turnCrediental: 'iamThdocReAL',

  agoraUrl: 'https://agora-backend.myeduroom.com',
  // agoraUrl: "http://localhost:3074",

  // BE_DEV: "https://doc.myeduroom.com",
  // BE_TEST: "https://vera-backend-test.myeduroom.com",
  // URL_DEV: "https://doc-call.myeduroom.com",
  // URL_TEST: "https://www.veradoctors.com",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
