import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreditCardValidators } from 'angular-cc-library';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.css'],
})
export class CreditCardComponent implements OnInit {
  @Output() messageEvent = new EventEmitter<FormGroup>();

  form: FormGroup;
  cardType: string = '';

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      cardholderName: ['', [Validators.required, Validators.minLength(3)]],
      creditCard: ['', [CreditCardValidators.validateCCNumber]],
      expirationDate: ['', [Validators.required, this.validateExpDate]],
      cvc: ['', [Validators.required, Validators.pattern('^[0-9]{3,4}$')]],
    });
  }

  ngOnInit(): void {}

  sendFormData() {
    this.detectCardType();
    this.messageEvent.emit(this.form);
  }

  detectCardType() {
    const cardNumber = this.form.get('creditCard')?.value;
    if (!cardNumber) {
      this.cardType = '';
      return;
    }

    const visaPattern = /^4/;
    const masterCardPattern = /^5[1-5]/;
    const amexPattern = /^3[47]/;
    const discoverPattern = /^6(?:011|5)/;
    const jcbPattern = /^(?:2131|1800|35)/;
    const dinersClubPattern = /^3(?:0[0-5]|[68])/;

    if (visaPattern.test(cardNumber)) {
      this.cardType = 'visa';
    } else if (masterCardPattern.test(cardNumber)) {
      this.cardType = 'mastercard';
    } else if (amexPattern.test(cardNumber)) {
      this.cardType = 'amex';
    } else if (discoverPattern.test(cardNumber)) {
      this.cardType = 'discover';
    } else if (jcbPattern.test(cardNumber)) {
      this.cardType = 'jcb';
    } else if (dinersClubPattern.test(cardNumber)) {
      this.cardType = 'dinnersclub';
    } else {
      this.cardType = '';
    }
  }

  formatCardNumber(event: any) {
    let value = event.target.value.replace(/\D/g, '');
    this.detectCardType();

    let formattedValue = '';

    if (this.cardType === 'amex') {
      // American Express Formatı: XXXX XXXXXX XXXXX (15 hane)
      value = value.substring(0, 15);
      formattedValue = value
        .replace(/^(\d{4})(\d{0,6})(\d{0,5})/, '$1 $2 $3')
        .trim();
    } else if (this.cardType === 'dinnersclub') {
      // Diners Club Formatı: XXXX XXXX XXXX XX (14 hane)
      value = value.substring(0, 14);
      formattedValue = value
        .replace(/^(\d{4})(\d{0,6})(\d{0,4})/, '$1 $2 $3')
        .trim();
    } else {
      value = value.substring(0, 16);
      formattedValue = value.replace(/(\d{4})/g, '$1 ').trim();
    }

    this.form.controls['creditCard'].setValue(formattedValue, {
      emitEvent: false,
    });
  }

  validateExpDate(control: any) {
    const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    return regex.test(control.value) ? null : { invalidDate: true };
  }

  formatExpDate(event: any) {
    let value = event.target.value.replace(/\D/g, '');
    if (value.length > 4) {
      value = value.substring(0, 4);
    }
    if (value.length >= 3) {
      value = value.replace(/^(\d{2})(\d{0,2})/, '$1/$2');
    }
    this.form.controls['expirationDate'].setValue(value);
  }

  restrictCVCInput(event: any) {
    const value = event.target.value.replace(/\D/g, '');
    const maxLength = this.cardType === 'Amex' ? 4 : 3;
    this.form.controls['cvc'].setValue(value.substring(0, maxLength));
  }
}
